import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://66913418f31b3276f04dcd501aa17a13@sentry.sec2.ru/3',

  integrations: [
    //   Подключение реплеев
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    // Проброс сообщений в консоли
    Sentry.captureConsoleIntegration(),
    // Проброс таймингов сессии
    Sentry.sessionTimingIntegration(),
    // Проброс http запросов
    Sentry.httpClientIntegration(),
  ],
  // Проброс хедеров и куки
  sendDefaultPii: true,
  // Отправлять 100% ошибок
  tracesSampleRate: 1.0,
  // Записывать 10% сессий
  replaysSessionSampleRate: 0.1,
  // Записывать 100% ошибочных сессий
  replaysOnErrorSampleRate: 1.0,
});
