import React, {FC} from 'react';
import styles from './endText.module.css'
import { useResize } from '../../hooks/useResize';

interface IProps {
  type: ComponentType,
  countNumber?: string,
  errorText?: string,
}

export enum ComponentType {
  OK,
  DUPLICATE,
  ERROR,
  NOTFOUND
}

export const EndText: FC<IProps> = ({countNumber, type, errorText}) => {
  const {isScreenSm} = useResize();

  switch (type) {
    case ComponentType.OK:
      return <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
        <h2>Заявка успешно оформлена!</h2>
        <p>Номер вашей заявки {countNumber} - сохраните этот номер, он вам понадобится при
          общении с оператором</p>
        <p>Представители гарантирующего поставщика имеют на руках доверенности на
          выполнение соответствующих видов работ от имени
          АО «Мосэнергосбыт» и подрядной организации
          ООО «СтройЭнергоКом». </p>
        <p>Просим Вас в даты проведения работ обеспечить свободный доступ к прибору учета мастерам.</p>
        <p>Замена (установка) прибора учета производится бесплатно!</p>
      </div>;
    case ComponentType.DUPLICATE:
      return <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
        <h2>Вы уже подавали заявку ранее</h2>
        <p>Ваша заявка находится в работе. В скором времени с вами свяжется оператор для уточнения даты</p>
        <p>По всем вопросам вы можете позвонить в Колл-Центр</p>
        <div className={styles.phone}>8 800 775 78 40</div>
      </div>;
    case ComponentType.NOTFOUND:
      return <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
        <h2>Ваш лицевой счет не найден в базе</h2>
        <p>Пожалуйста, свяжитесь с оператором для уточнения информации и оформления заявки </p>
        <div className={styles.phone}>8 800 775 78 40</div>
      </div>;
    case ComponentType.ERROR:
    default:
      return <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
        <h2>{errorText}</h2>
        <p>Пожалуйста, свяжитесь с оператором для уточнения информации и оформления заявки </p>
        <div className={styles.phone}>8 800 775 78 40</div>
      </div>;
  }
}
