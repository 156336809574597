export const getDateString = (date: Date): string =>
  `${start0(date.getDate())}.${start0(date.getMonth() + 1)}.${date.getFullYear()}`;

const start0 = (num: number): string => {
  if(num >= 10) {
    return `${num}`
  }
  else {
    return `0${num}`
  }
}