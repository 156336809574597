import React, {FC} from 'react';
import styles from './info.module.css'
import { useResize } from '../../hooks/useResize';

interface IProps {

}

export const Info: FC<IProps> = () => {
  const {isScreenSm} = useResize();
  return (
    <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
      <h3 className={`${styles.h3} ${isScreenSm ? styles.mobile : ''}`}>
        Уведомление о бесплатной замене электросчетчиков
      </h3>
      <p className={`${styles.p} ${isScreenSm ? styles.mobile : ''}`}>
        Обязанность по установке и замене счетчиков электроэнергии в многоквартирных
        домах перешла к гарантирующему поставщику согласно Федеральному закону №35-ФЗ
        "Об электроэнергетике". Для замены счетчика оставьте заявку через данный сайт
        или по телефону горячей линии 8 800 775 78 40 (ежедневно с 8:00 до 22:00) для
        согласования даты и времени работ в вашей квартире.
      </p>
      <h3 className={`${styles.h3} ${isScreenSm ? styles.mobile : ''}`}>
        Нормативная база
      </h3>
      <div className={`${styles.p} ${isScreenSm ? styles.mobile : ''}`}>
        <div>
          <a
            target='_blank'
            href='http://pravo.gov.ru/proxy/ips/?docbody=&firstDoc=1&lastDoc=1&nd=102500810'>
            1. Федеральный закон от 27 декабря 2018 года № 522-ФЗ.
          </a>
        </div>
        <div>
          <a
            target='_blank'
            href='http://pravo.gov.ru/proxy/ips/?docbody=&firstDoc=1&lastDoc=1&nd=102500810'>
            2. Постановление Правительства РФ от 29 июня 2020 года № 950.
          </a>
          </div>
        <div>
          <a
            target='_blank'
            href='http://pravo.gov.ru/proxy/ips/?docbody=&firstDoc=1&lastDoc=1&nd=102500810'>
            3. Федеральный закон №35-ФЗ "Об электроэнергетике"
          </a>
          </div>
      </div>
    </div>
  )
}