import * as React from 'react';
import Accordion__ from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import { text } from './const';
import { useResize } from '../../hooks/useResize';
import styles from '../Stepper/stepper.module.css';


const Accordion = styled(Accordion__)(({ theme }) => {
  return {
    ':before': {
      display: 'none'
    },
    background: 'none',
    boxShadow: 'none',
    border: `none`,
    '.MuiAccordionDetails-root': {
      // background: 'blue',
      paddingLeft: 40
    },
    '.MuiAccordionSummary-root': {
      background: 'none',
      flexDirection: 'row-reverse'
    },
  };
});

const questionStyle = (isMobile: boolean) =>
  isMobile ?
    {
      fontFamily: '"Georgia"',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '30px',
      letterSpacing: '0.02em',
    } :
    {
      fontFamily: '"Georgia"',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '30px',
      letterSpacing: '0.02em',
    };
const answerStyle = (isMobile: boolean) =>
  isMobile ?
    {
      fontFamily: '"Georgia"',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '19px',
      letterSpacing: '0.02em',
    } :
    {
      fontFamily: '"Georgia"',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '19px',
      letterSpacing: '0.02em',
    };


export default function BasicAccordion() {
  const {isScreenSm, isScreenLg} = useResize();
  return (
    <div style={{flex: 1}}>
      <h3
        style={{
          marginTop: isScreenLg? '28px' : '0',
          paddingLeft: '16px',
          textAlign: 'left',
          fontFamily: '"Georgia"',
          fontSize: '24px',
          fontWeight: '400',
          lineHeight: '30px',
          letterSpacing: '0.02em',
        }}
      >Частые вопросы</h3>
      {text.map(({answer, question}, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`accordion-${index}-control`}
            id={`accordion-${index}`}
          >
            <div style={questionStyle(isScreenSm)}>{question}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div style={answerStyle(isScreenSm)}>
              {answer}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}