import React from 'react';
import { VIDEOS } from '../../videos/description';
import { Video } from '../Video';
import { useResize } from '../../hooks/useResize';
import styles from './index.module.css';

export const VideoGallery = () => {
  const {isScreenMd} = useResize();
  return (
    <div className={`${styles.wrapper} ${isScreenMd ? styles.mobile : ''}`}>
      {VIDEOS.map(elem =>
          <Video {...elem} key={elem.name} />
      )}
    </div>
  );
};
