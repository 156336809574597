import React, {FC} from 'react';
import { Info } from '../Info';
import { Stepper } from '../Stepper';
import BasicAccordion from '../Accordion';
import styles from './screen.module.css';
import { useResize } from '../../hooks/useResize';
import { VideoGallery } from '../VideoGallery';
import { About } from '../About';
import { Counter } from '../Counter';

interface IProps {

}

export const Screen: FC<IProps> = () => {
  const {isScreenLg} = useResize();
  return (
    <>
      <div className={`${styles.wrapper} ${isScreenLg ? styles.mobile : ''}`}>
        <Info/>
        <BasicAccordion />
      </div>
      <Stepper />
      {isScreenLg && <Counter />}
      <About/>
      <VideoGallery />
    </>
  )
}