import React from 'react';
import styles from './index.module.css';

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className={styles.br} />
      <div className={styles.text}>© 2009-{currentYear} ООО «СтройЭнергоКом»</div>
    </>
  );
};
