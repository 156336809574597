import React, { FC } from 'react';
import styles from './input.module.css';
import { useResize } from '../../hooks/useResize';
import InputMask from 'react-input-mask';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  value?: string;
  onChange?: (e: any) => void;
  mask: string | (string | RegExp)[];
}

export const Input: FC<IProps> = ({ label, value, onChange, error, mask, ...otherProps }) => {
  const { isScreenSm } = useResize();
  const onChangeHandler = (event: any) => {
    onChange && onChange(event.target.value);
  };

  return (
    <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
      <div className={`${styles.label} ${isScreenSm ? styles.mobile : ''}`}>{label}</div>
      <InputMask
        mask={mask}
        maskChar=''
        className={`${styles.input} ${isScreenSm ? styles.mobile : ''} ${
          error ? styles.error : ''
        }`}
        value={value}
        onChange={onChangeHandler}
        {...otherProps}
      />
      {error && (
        <div className={`${styles.errorText} ${isScreenSm ? styles.mobile : ''}`}>{error}</div>
      )}
    </div>
  );
};
