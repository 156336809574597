export const text = [
  {
    question: 'Какие приборы учета подлежат замене',
    answer: 'Приборы учета с истекшим межповерочным интервалом. Приборы учета, вышедшие из строя.'
  },
  {
    question: 'Могут ли потребители сами заменить прибор учета',
    answer: 'Клиенты вправе заменить прибор учета своими силами или с привлечением сторонней организации, только если он исправен и не истек срок МПИ. Согласно Постановлению Правительства РФ №890 от 19.06.2020 «О порядке предоставления доступа к минимальному набору функций интеллектуальных систем учета электрической энергии (мощности)» с 1 января 2022г. гарантирующий поставщик обязан производить приемку приборов учета электроэнергии в случае, если прибор учета соответствует утвержденным Правилам Если прибор учета не соответствует указанным требованиям, то такой прибор учета не может быть принят в эксплуатацию.'
  },
  {
    question: 'За чей счет производится замена прибора учета',
    answer: 'Замена прибора учета электроэнергии в жилых помещениях многоквартирных домов в Москве и Московской области производится за счет гарантирующего поставщика электроэнергии, который в данном случае является АО "Мосэнергосбыт". В индивидуальных жилых домах эта обязанность возложена на сетевые организации ПАО "Россети Московский регион". В остальных случаях замена или установка прибора учета осуществляется на платной основе за счет средств потребителя. Установленный прибор учета должен быть опломбирован энергоснабжающей организацией. В случае, если прибор учета входит в систему АИИСКУЭ и находится на балансе АО "Мосэнергосбыт", замена осуществляется на безвозмездной основе.'
  }
]