// @ts-ignore
import v1 from '../videos/v1.mp4'
// @ts-ignore
import v2 from '../videos/v2.mp4'

export const VIDEOS = [
  {
    name: 'Замена счетчиков: теперь бесплатно! Доброе утро. Фрагмент выпуска от 06.08.2020',
    link: 'https://www.1tv.ru/shows/dobroe-utro/pro-dengi/zamena-schetchikov-teper-besplatno-dobroe-utro-fragment-vypuska-ot-06-08-2020',
    src: v1
  },
  {
    name: 'Счетчик, ну ты и насчитал… Доброе утро. Фрагмент выпуска от 30.03.2023',
    link: 'https://www.1tv.ru/shows/dobroe-utro/pro-dengi/schetchik-nu-ty-i-naschital-dobroe-utro-fragment-vypuska-ot-30-03-2023',
    src: v2
  }
]