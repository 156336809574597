import React, {FC} from 'react';
import styles from './stepper.module.css'
import { useResize } from '../../hooks/useResize';
import { steps } from './const';

interface IProps {

}

export const Stepper: FC<IProps> = () => {
  const {isScreenMd} = useResize();
  return (
    <>
      <h3 className={styles.h3}>Как это работает</h3>
      <div className={`${styles.wrapper} ${isScreenMd ? styles.mobile : ''}`}>
          {steps.map((elem, index) => (
            isScreenMd ?
              (<div key={index} className={styles.itemVert}>
                <div className={`${styles.circle} ${isScreenMd ? styles.mobile : ''}`}/>
                <div className={styles.dashedVert}/>
                <div className={styles.info}>{elem}</div>
                <div className={styles.dashedVert}/>
                {index == steps.length - 1 &&
                  <div className={`${styles.circle} ${isScreenMd ? styles.mobile : ''}`}/>
                }
              </div>)
              :
              (<div key={index} className={styles.item}>
                <div className={`${styles.circle} ${isScreenMd ? styles.mobile : ''}`}>
                  {index !== steps.length - 1 && <div className={styles.dashed}/>}
                </div>
                <div className={styles.info}>{elem}</div>
              </div>)
          ))}
      </div>
    </>
  )
}