import React, { FC } from 'react';
import styles from './form.module.css';
import { useResize } from '../../hooks/useResize';
import { Input } from '../Input';
import { useMainStore } from '../../store/store';
import { consumerClaim, sendRequestToCallcenter, sendSmsToPhone } from '../../api/api';
import { errorsHandler } from '../../utils/errorsHandler';
import { clearPhoneNumber } from '../../utils/clearString';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { ComponentType, EndText } from '../EndText';
import { Counter } from '../Counter';

interface IProps {}

export const Form: FC<IProps> = () => {
  const { isScreenSm, isScreenLg } = useResize();
  const {
    endText,
    setEndText,
    setEndStep,
    step,
    setNextStep,
    clearStep,
    phoneErr,
    codeErr,
    countErr,
    phone,
    count,
    code,
    fullName,
    fullNameErr,
    clearFullName,
    clearCount,
    clearCode,
    setCode,
    setCount,
    setPhone,
    clearPhone,
    setCodeErr,
    clearCodeErr,
    setFullName,
  } = useMainStore();

  const sendSms = () => {
    if (fullName && phone && count && !fullNameErr && !phoneErr && !countErr) {
      sendSmsToPhone(phone)
        .then(() => {
          setNextStep();
        })
        .catch((err) => {
          clearCount();
          clearPhone();
          clearFullName();
          clearCode();
          setEndStep();
          setEndText(
            <EndText
              type={ComponentType.ERROR}
              errorText={errorsHandler(err?.response)}
            />
          );
        });
    }
  };

  const sendCode = () => {
    fullName &&
      phone &&
      count &&
      !phoneErr &&
      !countErr &&
      !fullNameErr &&
      code &&
      sendRequestToCallcenter(fullName, clearPhoneNumber(phone), count, code)
        .then((res) => {
          clearCount();
          clearPhone();
          clearFullName();
          clearCode();
          return consumerClaim(res.data.consumerClaimRequestId);
        })
        .then((res) => {
          setEndStep();
          setEndText(
            <EndText
              type={ComponentType.OK}
              countNumber={`${res.data}`}
            />
          );
        })
        .catch((err) => {
          if (err?.response?.status == '403' && step < 3) {
            setNextStep();
            setCodeErr();
          } else {
            setEndStep();
            setEndText(
              <EndText
                type={ComponentType.ERROR}
                errorText={errorsHandler(err?.response)}
              />
            );
            clearCount();
            clearPhone();
            clearFullName();
          }
        })
        .finally(() => {
          clearCode();
        });
  };

  const onClick = () => {
    if (step === 0) {
      sendSms();
    }
    if (step >= 1) {
      sendCode();
    }
  };

  return (
    <>
      {step < 5 && (
        <div className={`${styles.baner} ${isScreenSm ? styles.mobile : ''}`}>
          <div
            className={`${styles.wrapper} ${isScreenLg ? styles.lg : ''} ${
              isScreenSm ? styles.mobile : ''
            }`}
          >
            <Input
              label={'Ф.И.О.'}
              mask={''}
              value={fullName}
              onChange={setFullName}
              error={fullNameErr}
            />

            <Input
              label={'Номер телефона'}
              mask={'+7 (999) 999 99 99'}
              value={phone}
              onChange={setPhone}
              error={phoneErr}
            />
            <Input
              label={'Лицевой счет'}
              mask={''}
              value={count}
              onChange={setCount}
              error={countErr}
              maxLength={12}
            />
            {(step === 1 || step === 2 || step === 3) && (
              <Input
                label={'Код из СМС'}
                value={code}
                onChange={(e) => {
                  setCode(e);
                  clearCodeErr();
                }}
                mask='9999'
                error={codeErr}
              />
            )}
            <div
              className={`${styles.forget} ${isScreenSm ? styles.mobile : ''}`}
              onClick={() => {
                setEndStep();
                setEndText(<EndText type={ComponentType.ERROR} />);
                clearCount();
                clearPhone();
                clearFullName();
              }}
            >
              Я не помню свой лицевой счет
            </div>

            <p className={`${styles.persData} ${isScreenSm ? styles.mobile : ''}`}>
              Нажимая кнопку "Отправить", вы принимаете{' '}
              <a
                href='/files/Соглашение_на_обработку_ПД.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Согласие на обработку персональных данных
              </a>
            </p>
            <button
              className={styles.button}
              onClick={onClick}
              disabled={
                step === 0 &&
                !(fullName && phone && count && !fullNameErr && !phoneErr && !countErr)
              }
            >
              отправить
            </button>
          </div>
          {!isScreenLg && <Counter />}
        </div>
      )}
      {step === 5 && (
        <div className={`${styles.endTextWrapper} ${isScreenSm ? styles.mobile : ''}`}>
          <ArrowBackIosNewRoundedIcon
            fontSize='large'
            style={{ color: '#3B91CB', cursor: 'pointer', marginBottom: 10 }}
            onClick={() => {
              clearCount();
              clearCode();
              clearPhone();
              clearFullName();
              clearStep();
            }}
          />
          <div>{endText}</div>
        </div>
      )}
    </>
  );
};
