import React, {FC} from 'react';
import Logo from '../../svg/logo';
import MesIcon from '../../svg/mesIcon';
import styles from './header.module.css'
import { useResize } from '../../hooks/useResize';

interface IProps {

}

export const Header: FC<IProps> = () => {
	const {isScreenMd} = useResize();
	return (
		<>
			<div className={styles.main}>
				{!isScreenMd && <MesIcon/>}
				<Logo/>
				{isScreenMd && <MesIcon className={styles.lastElem}/>}
				{!isScreenMd && <div className={styles.lastElem}>
					<div className={styles.phone}>8 800 775 78 40</div>
					<div className={styles.location}>Москва и область</div>
				</div>}
			</div>
		</>
	)
}