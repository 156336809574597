import React from 'react';
import { useResize } from '../hooks/useResize';

const Arrow = ({top}: {top: boolean}) => {
  const {isScreenSm} = useResize();
  return (
    <div style={{transform: `rotate(${top?'180':'0'}deg)`, cursor: 'pointer'}}>
      <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.4769 10.3668L18.2456 2.58427C18.3745 2.45645 18.4768 2.30437 18.5466 2.13682C18.6164 1.96926 18.6523 1.78954 18.6523 1.60802C18.6523 1.42651 18.6164 1.24679 18.5466 1.07923C18.4768 0.911674 18.3745 0.759597 18.2456 0.631773C17.988 0.375678 17.6395 0.231934 17.2762 0.231934C16.913 0.231934 16.5645 0.375678 16.3069 0.631773L9.43187 7.43802L2.62562 0.631773C2.368 0.375677 2.0195 0.231933 1.65625 0.231933C1.29299 0.231933 0.944496 0.375677 0.686872 0.631772C0.556951 0.759118 0.453592 0.910979 0.382786 1.07856C0.311977 1.24614 0.275129 1.4261 0.274374 1.60802C0.275129 1.78994 0.311977 1.96991 0.382786 2.13749C0.453592 2.30507 0.556951 2.45693 0.686872 2.58427L8.45562 10.3668C8.58438 10.5063 8.74065 10.6177 8.91459 10.6939C9.08852 10.7701 9.27636 10.8094 9.46625 10.8094C9.65614 10.8094 9.84397 10.7701 10.0179 10.6939C10.1918 10.6177 10.3481 10.5063 10.4769 10.3668Z" fill="#595959"/>
      </svg>
    </div>
  );
};

export default Arrow;