import React, { FC, useState } from 'react';
import styles from './index.module.css';
import { useResize } from '../../hooks/useResize';
import Arrow from '../../svg/arrow';

const LIST: string[] = [
  'АО "Мосэнергосбыт"',
  'АО "Петербургская сбытовая компания"',
  'ООО "Орловский энергосбыт"',
  'ПАО "Тамбовская энергосбытовая компания"',
  'ПАО "Саратовэнерго"',
  'ООО "Энергетическая сбытовая компания Башкортостана"',
  'АО "Алтайэнергосбыт"',
  'АО "Томскэнергосбыт"',
  'ООО "Омская энергосбытовая копания"',
  'ООО "Энергосбыт Волга"',
  'ООО "Северная сбытовая компания"',
  'ПАО "Россети Московский регион"',
  'ПАО "Россети Ленэнерго"',
  'ПАО "Россети Урал"',
  'ПАО "Россети Кубань"',
  'АО "Екатеринбургэнергосбыт"',
  'АО "Псковэнергосбыт"',
];

interface AboutProps {}

export const About: FC<AboutProps> = ({}) => {
  const { isScreenSm, isScreenLg } = useResize();
  const [isHideAll, setIsHideAll] = useState<boolean>(true);
  return (
    <div className={`${styles.wrapperMain} ${isScreenLg ? styles.mobile : ''}`}>
      <h3 className={styles.h3}>О компании</h3>
      <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
        <p className={`${styles.p} ${isScreenSm ? styles.mobile : ''}`}>
          "Строительная энергетическая компания" (СЭК) – это инжиниринговая компания, которая
          специализируется на предоставлении полного спектра услуг в области технологического и
          строительного инжиниринга на объектах энергетического комплекса Российской Федерации.
          Компания была основана в 2008 году и с тех пор активно развивается и реализует проекты в
          сфере энергетики.
        </p>
        <p className={`${styles.p} ${isScreenSm ? styles.mobile : ''}`}>Наши заказчики:</p>
        {LIST.map((elem, index) =>
          !isHideAll || index < 4 ? (
            <p
              key={index}
              className={`${styles.p} ${isScreenSm ? styles.mobile : ''}`}
            >
              {index + 1}. {elem}
            </p>
          ) : null
        )}
        {}
        <p className={`${styles.p} ${isScreenSm ? styles.mobile : ''}`}>
          {!isHideAll
            ? 'Мы стремимся обеспечивать высокое качество и профессионализм во всех наших проектах, и наш опыт и компетенции позволяют нам успешно выполнять работы для этих организаций в энергетической сфере.'
            : '...'}
        </p>

        <div
          className={styles.toggle}
          onClick={() => setIsHideAll(!isHideAll)}
        >
          {isHideAll && <div className={styles.description}>Показать еще</div>}
          <Arrow top={!isHideAll} />
        </div>
      </div>
    </div>
  );
};
