import React, {FC} from 'react';
import styles from './baner.module.css'
import { useResize } from '../../hooks/useResize';
import fork from '../../images/fork.png';

interface IProps {

}

export const Baner: FC<IProps> = () => {
  const {isScreenSm} = useResize();
  return (
    <>
      <div className={styles.baner}>
        <div className={`${styles.wrapper} ${isScreenSm ? styles.mobile : ''}`}>
          <h1 className={`${styles.h1} ${isScreenSm ? styles.mobile : ''}`}>
            Бесплатная замена счетчика электроэнергии
          </h1>
          <img
            src={fork}
            className={`${styles.img} ${isScreenSm ? styles.mobile : ''}`}
          />
        </div>
      </div>
    </>
  )
}