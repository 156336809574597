import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { getCountInstallation } from '../../api/api';
import { useResize } from '../../hooks/useResize';
import { CircularProgress } from '@mui/material';

const getSecondsToday = () => {
  let d = new Date();
  return d.getHours() * 3600 + d.getMinutes() * 60 + d.getSeconds();
};

export const Counter = () => {
  const [count, setCount] = useState<number | null>(null);
  const [showLongRequestSpinner, setShowLongRequestSpinner] = useState(false);
  const { isScreenLg } = useResize();

  const getCount = () => {
    const timer = setTimeout(() => setShowLongRequestSpinner(true), 600);

    getCountInstallation()
      .then(({ data }) => {
        clearTimeout(timer);
        setCount(data);
      })
      .catch(() => {
        clearTimeout(timer);
        setCount(1000 + Math.round(getSecondsToday() / 50));
      })
      .finally(() => setShowLongRequestSpinner(false));
  };
  useEffect(getCount, []);

  return (
    <div
      className={styles.wrapper}
      style={{ width: isScreenLg ? '100%' : 'calc(34% - 60px)' }}
    >
      <div className={styles.text}>Каждый счетчик важен</div>
      <div className={styles.counter}>
        {showLongRequestSpinner && !count ? <CircularProgress color='inherit' /> : count}
      </div>
      <div className={styles.text}>новых установок сегодня</div>
    </div>
  );
};
