import React from 'react';
import { useResize } from '../hooks/useResize';

const Logo = () => {
  const {isScreenSm} = useResize();
  return (
    <div style={{width: isScreenSm? '180px' : '306px'}}>
      <svg viewBox="0 0 306 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.0174 8.60068C20.6396 6.6502 23.8709 5.69766 27.1319 5.91385C30.3928 6.13004 33.4701 7.50082 35.8119 9.78039C36.7181 10.6648 37.4987 11.6693 38.1318 12.7659C36.7317 9.0239 34.2243 5.7975 30.9439 3.51675C27.6635 1.23601 23.7658 0.00925087 19.7705 0H19.4278C16.0027 0.0910241 12.7484 1.51545 10.3579 3.97C7.96737 6.42454 6.62941 9.71529 6.62891 13.1416C6.62983 15.2923 7.15703 17.4102 8.16451 19.3103C9.28419 19.2802 10.3658 18.8977 11.2556 18.2173C12.1454 17.537 12.798 16.5934 13.1206 15.5208C13.9888 12.7586 15.7015 10.3384 18.0174 8.60068Z" fill="#31317B"/>
        <path d="M26.2433 24.022C25.3795 26.797 23.6639 29.2297 21.34 30.975C19.7809 32.1333 17.9945 32.9488 16.0981 33.3679C14.2016 33.7871 12.2379 33.8004 10.3359 33.407C8.43395 33.0137 6.63665 32.2226 5.06201 31.0856C3.48736 29.9486 2.17096 28.4915 1.19922 26.8098C2.60625 30.5498 5.11993 33.7721 8.40512 36.047C11.6903 38.322 15.5909 39.5414 19.5869 39.5428H19.9296C23.3558 39.4551 26.612 38.0317 29.0032 35.5764C31.3944 33.1211 32.7313 29.8285 32.7285 26.4012C32.7296 24.251 32.2046 22.1332 31.1994 20.2324C30.0798 20.2626 28.9981 20.6451 28.1083 21.3254C27.2185 22.0058 26.5659 22.9494 26.2433 24.022Z" fill="#31317B"/>
        <path d="M13.1416 32.8205C15.2925 32.8212 17.4107 32.2939 19.3103 31.2849C19.2724 30.1712 18.8864 29.0974 18.2067 28.2143C17.527 27.3313 16.5877 26.6834 15.5208 26.3617C12.7586 25.4935 10.3384 23.7809 8.60068 21.465C6.6502 18.8427 5.69766 15.6114 5.91385 12.3505C6.13004 9.08953 7.50082 6.01226 9.78039 3.67046C10.6626 2.7526 11.6673 1.96086 12.7659 1.31763C9.02232 2.71535 5.79436 5.22213 3.51324 8.50307C1.23213 11.784 0.00646765 15.6829 0 19.6789V20.0216C0.0877018 23.4478 1.51108 26.704 3.96635 29.0952C6.42162 31.4865 9.71428 32.8233 13.1416 32.8205Z" fill="#31317B"/>
        <path d="M39.5428 19.8648C39.5428 19.7726 39.5428 19.6737 39.5428 19.5815V19.5221C39.4851 17.2832 38.8562 15.0962 37.7158 13.1687C36.5754 11.2411 34.9613 9.63703 33.0267 8.50863C31.0921 7.38022 28.9012 6.76496 26.662 6.72125C24.4228 6.67754 22.2096 7.20683 20.2324 8.25887C20.2684 9.37312 20.6536 10.4479 21.3336 11.3313C22.0136 12.2147 22.9541 12.8621 24.022 13.182C26.7985 14.057 29.2305 15.7812 30.975 18.1118C32.1274 19.6722 32.9372 21.4584 33.3513 23.3535C33.7655 25.2486 33.7747 27.2098 33.3784 29.1087C32.982 31.0076 32.1891 32.8013 31.0515 34.3725C29.9138 35.9437 28.4572 37.2569 26.7768 38.2261C30.5189 36.826 33.7453 34.3187 36.026 31.0383C38.3067 27.7578 39.5335 23.8602 39.5428 19.8648Z" fill="#31317B"/>
        <path d="M54.1223 5.43015C55.0252 3.83879 56.3384 2.51875 57.925 1.60762C59.5508 0.675162 61.3958 0.192878 63.27 0.210423C64.7279 0.200155 66.1717 0.496551 67.5077 1.08038C68.8056 1.64408 69.9642 2.48545 70.9018 3.54525C71.857 4.6182 72.5755 5.88019 73.0108 7.24914H69.7485C69.1942 5.99731 68.3047 4.92305 67.1782 4.14499C66.0205 3.38178 64.6561 2.99294 63.27 3.03118C61.9359 3.01566 60.6237 3.37165 59.4804 4.05931C58.3481 4.73656 57.4192 5.70642 56.7915 6.86689C56.1425 8.07325 55.8028 9.42172 55.8028 10.7916C55.8028 12.1614 56.1425 13.5099 56.7915 14.7162C57.4192 15.8767 58.3481 16.8466 59.4804 17.5238C60.6218 18.2167 61.9348 18.5752 63.27 18.5585C64.6556 18.5866 66.0167 18.1911 67.1716 17.425C68.2951 16.6436 69.1838 15.5703 69.7419 14.3208H73.0042C72.5705 15.695 71.8544 16.9633 70.9018 18.0445C69.9642 19.1043 68.8056 19.9456 67.5077 20.5093C66.1717 21.0932 64.7279 21.3896 63.27 21.3793C61.3936 21.398 59.5462 20.9157 57.9184 19.9821C56.3332 19.0692 55.0204 17.7496 54.1157 16.1596C53.1997 14.5199 52.7188 12.673 52.7188 10.7949C52.7188 8.91669 53.1997 7.0698 54.1157 5.43015H54.1223Z" fill="#31317B"/>
        <path d="M87.5635 9.02978H82.4228V21.0905H79.6284V9.02978H74.4746V6.39355H87.5569L87.5635 9.02978Z" fill="#31317B"/>
        <path d="M101.647 7.07853C102.706 7.74224 103.564 8.68067 104.132 9.79384C104.734 11.025 105.047 12.3775 105.047 13.7482C105.047 15.1188 104.734 16.4714 104.132 17.7025C103.561 18.8174 102.697 19.7559 101.634 20.4178C100.575 21.077 99.349 21.42 98.1013 21.4064C97.1413 21.4111 96.1943 21.1851 95.3399 20.7473C94.4785 20.3087 93.7264 19.6826 93.1386 18.9152V26.0857H90.3574V6.38652H92.9936V8.76571C93.5925 7.94585 94.3671 7.27031 95.2608 6.78855C96.1367 6.3212 97.1151 6.07887 98.1079 6.08336C99.3584 6.07047 100.587 6.4158 101.647 7.07853ZM99.973 18.1441C100.667 17.7018 101.225 17.077 101.588 16.3383C101.974 15.5248 102.174 14.6355 102.174 13.735C102.174 12.8345 101.974 11.9452 101.588 11.1317C101.225 10.393 100.667 9.76821 99.973 9.32591C99.2774 8.88635 98.4694 8.65746 97.6466 8.66686C96.8163 8.65596 95.9999 8.88045 95.2919 9.31434C94.5839 9.74823 94.0133 10.3738 93.6461 11.1185C93.2599 11.932 93.0595 12.8213 93.0595 13.7218C93.0595 14.6223 93.2599 15.5116 93.6461 16.3251C94.0133 17.0699 94.5839 17.6954 95.2919 18.1293C95.9999 18.5632 96.8163 18.7877 97.6466 18.7768C98.4668 18.7941 99.2746 18.5744 99.973 18.1441Z" fill="#31317B"/>
        <path d="M111.11 20.3655C109.933 19.7041 108.953 18.7413 108.272 17.5761C107.59 16.411 107.23 15.0853 107.23 13.7354C107.23 12.3854 107.59 11.0598 108.272 9.89459C108.953 8.72941 109.933 7.76668 111.11 7.10527C112.316 6.42671 113.68 6.07674 115.064 6.09032C116.438 6.07505 117.791 6.42533 118.985 7.10527C120.159 7.76949 121.135 8.73321 121.814 9.89803C122.493 11.0629 122.851 12.387 122.851 13.7354C122.851 15.0837 122.493 16.4079 121.814 17.5727C121.135 18.7375 120.159 19.7012 118.985 20.3655C117.79 21.0425 116.438 21.3926 115.064 21.3804C113.68 21.3909 112.317 21.0412 111.11 20.3655ZM117.549 18.1313C118.282 17.6906 118.884 17.0598 119.289 16.3057C119.713 15.5153 119.934 14.6323 119.934 13.7354C119.934 12.8384 119.713 11.9554 119.289 11.1651C118.875 10.4061 118.263 9.77393 117.517 9.33625C116.772 8.89857 115.922 8.67186 115.057 8.68041C114.174 8.66932 113.304 8.89703 112.54 9.33947C111.79 9.77212 111.174 10.404 110.76 11.1651C110.336 11.9554 110.114 12.8384 110.114 13.7354C110.114 14.6323 110.336 15.5153 110.76 16.3057C111.174 17.0668 111.79 17.6986 112.54 18.1313C113.304 18.5737 114.174 18.8014 115.057 18.7903C115.933 18.8046 116.795 18.5765 117.549 18.1313Z" fill="#31317B"/>
        <path d="M126.223 6.3861H129.017V17.3198L136.662 6.3861H139.72V21.0896H136.952V10.1493L129.314 21.0896H126.223V6.3861ZM129.221 3.74988C128.738 3.3555 128.341 2.86468 128.058 2.30868C127.774 1.75268 127.609 1.14369 127.574 0.520508H129.986C130.018 0.859918 130.118 1.18942 130.28 1.48945C130.442 1.78947 130.662 2.05389 130.928 2.267C131.535 2.67926 132.251 2.8997 132.985 2.8997C133.718 2.8997 134.434 2.67926 135.041 2.267C135.307 2.05389 135.527 1.78947 135.689 1.48945C135.851 1.18942 135.951 0.859918 135.983 0.520508H138.402C138.367 1.14369 138.202 1.75268 137.918 2.30868C137.635 2.86468 137.238 3.3555 136.754 3.74988C135.656 4.53458 134.341 4.95644 132.991 4.95644C131.642 4.95644 130.326 4.53458 129.228 3.74988H129.221Z" fill="#31317B"/>
        <path d="M158.386 1.60842C159.944 2.52346 161.224 3.84471 162.089 5.43094C162.981 7.07864 163.447 8.92239 163.447 10.7957C163.447 12.6689 162.981 14.5127 162.089 16.1604C161.215 17.7537 159.926 19.0804 158.358 19.9995C156.79 20.9186 155.003 21.3957 153.186 21.3801C150.956 21.4288 148.77 20.7587 146.951 19.4688C145.192 18.2047 143.899 16.3954 143.273 14.3216H146.424C146.981 15.6356 147.928 16.7467 149.138 17.5047C150.347 18.2626 151.76 18.6307 153.186 18.5593C154.336 18.571 155.471 18.2874 156.481 17.7355C157.483 17.1848 158.339 16.4031 158.979 15.4552C159.656 14.473 160.09 13.3433 160.244 12.1599H150.628V9.39187H160.238C160.084 8.20848 159.65 7.07882 158.972 6.09659C158.334 5.14717 157.478 4.36506 156.474 3.81626C155.464 3.26437 154.33 2.98073 153.179 2.99244C151.752 2.92302 150.339 3.29434 149.13 4.05601C147.922 4.81767 146.977 5.93278 146.424 7.24994H143.273C143.907 5.17976 145.206 3.37703 146.971 2.12248C148.79 0.83262 150.976 0.162493 153.205 0.211221C155.027 0.197286 156.818 0.680344 158.386 1.60842Z" fill="#31317B"/>
        <path d="M180.344 6.38574V21.0893H177.557V15.0589H169.912V21.0893H167.117V6.38574H169.912V12.4095H177.557V6.38574H180.344Z" fill="#31317B"/>
        <path d="M195.207 7.05982C196.327 7.69976 197.232 8.65577 197.811 9.80808C198.444 11.1036 198.758 12.5317 198.727 13.9733C198.727 14.3819 198.727 14.6851 198.727 14.8828H186.64C186.823 15.9803 187.391 16.9765 188.243 17.6928C189.094 18.409 190.173 18.7984 191.286 18.791C193.184 18.791 194.542 18.132 195.346 16.7018H198.226C197.746 18.0862 196.818 19.2713 195.59 20.0696C194.32 20.9458 192.809 21.4064 191.266 21.3877C189.908 21.4065 188.568 21.0654 187.384 20.3991C186.244 19.7603 185.306 18.8151 184.676 17.6706C184.039 16.459 183.707 15.1111 183.707 13.7426C183.707 12.3742 184.039 11.0262 184.676 9.81467C185.305 8.67381 186.238 7.72945 187.371 7.08618C188.548 6.41911 189.881 6.07786 191.233 6.09759C192.62 6.06002 193.991 6.39213 195.207 7.05982ZM188.215 9.64991C187.382 10.3672 186.833 11.3581 186.666 12.4443H195.695C195.657 11.9023 195.511 11.3733 195.267 10.8879C195.022 10.4026 194.684 9.97058 194.271 9.61695C193.409 8.90488 192.318 8.53016 191.2 8.56246C190.104 8.53939 189.039 8.9273 188.215 9.64991Z" fill="#31317B"/>
        <path d="M213.389 7.07866C214.456 7.7383 215.322 8.67731 215.894 9.79397C216.496 11.0251 216.809 12.3776 216.809 13.7483C216.809 15.119 216.496 16.4715 215.894 17.7026C215.322 18.8193 214.456 19.7583 213.389 20.4179C212.333 21.078 211.109 21.4211 209.863 21.4065C208.903 21.412 207.956 21.186 207.102 20.7475C206.238 20.3091 205.484 19.683 204.894 18.9153V26.0858H202.1V6.38665H204.736V8.76584C205.335 7.94598 206.109 7.27044 207.003 6.78867C207.878 6.31896 208.857 6.07649 209.85 6.08348C211.101 6.06837 212.33 6.41387 213.389 7.07866ZM211.715 18.1442C212.411 17.7023 212.972 17.0776 213.337 16.3384C213.723 15.5249 213.923 14.6356 213.923 13.7351C213.923 12.8346 213.723 11.9454 213.337 11.1319C212.972 10.3926 212.411 9.76794 211.715 9.32604C211.022 8.88561 210.216 8.65659 209.395 8.66698C208.565 8.65608 207.749 8.88057 207.041 9.31446C206.333 9.74835 205.762 10.3739 205.395 11.1187C205.009 11.9322 204.808 12.8214 204.808 13.7219C204.808 14.6225 205.009 15.5117 205.395 16.3252C205.762 17.07 206.333 17.6955 207.041 18.1294C207.749 18.5633 208.565 18.7878 209.395 18.7769C210.214 18.7952 211.02 18.5754 211.715 18.1442Z" fill="#31317B"/>
        <path d="M230.471 9.02856H222.978V21.0893H220.184V6.38574H230.471V9.02856Z" fill="#31317B"/>
        <path d="M235.203 20.3655C234.027 19.7041 233.047 18.7413 232.365 17.5761C231.684 16.411 231.324 15.0853 231.324 13.7354C231.324 12.3854 231.684 11.0598 232.365 9.89459C233.047 8.72941 234.027 7.76668 235.203 7.10527C236.41 6.42671 237.774 6.07674 239.158 6.09032C240.532 6.07505 241.885 6.42533 243.079 7.10527C244.252 7.76949 245.229 8.73321 245.908 9.89803C246.587 11.0629 246.945 12.387 246.945 13.7354C246.945 15.0837 246.587 16.4079 245.908 17.5727C245.229 18.7375 244.252 19.7012 243.079 20.3655C241.884 21.0425 240.531 21.3926 239.158 21.3804C237.774 21.39 236.411 21.0403 235.203 20.3655ZM241.642 18.1313C242.386 17.6943 242.996 17.0632 243.409 16.3057C243.833 15.5153 244.055 14.6323 244.055 13.7354C244.055 12.8384 243.833 11.9554 243.409 11.1651C242.995 10.4061 242.383 9.77393 241.637 9.33625C240.892 8.89857 240.042 8.67186 239.178 8.68041C238.294 8.66932 237.424 8.89703 236.66 9.33947C235.913 9.77587 235.298 10.4068 234.88 11.1651C234.456 11.9554 234.235 12.8384 234.235 13.7354C234.235 14.6323 234.456 15.5153 234.88 16.3057C235.298 17.0639 235.913 17.6949 236.66 18.1313C237.424 18.5737 238.294 18.8014 239.178 18.7903C240.044 18.7998 240.896 18.5719 241.642 18.1313Z" fill="#31317B"/>
        <path d="M256.549 11.3556L253.551 14.1434V21.0899H250.611V0.507568H253.551V10.7361L264.458 0.507568H268.076L258.665 9.32574L268.366 21.0899H264.517L256.549 11.3556Z" fill="#31317B"/>
        <path d="M272.665 20.3655C271.487 19.7055 270.505 18.7432 269.822 17.5779C269.139 16.4125 268.779 15.0862 268.779 13.7354C268.779 12.3846 269.139 11.0582 269.822 9.89285C270.505 8.72749 271.487 7.76526 272.665 7.10527C273.872 6.42671 275.235 6.07674 276.62 6.09032C277.994 6.07505 279.347 6.42533 280.541 7.10527C281.725 7.75911 282.711 8.71986 283.395 9.88648C284.073 11.0631 284.429 12.3972 284.429 13.7551C284.429 15.1131 284.073 16.4471 283.395 17.6238C282.719 18.7756 281.75 19.7282 280.587 20.3852C279.392 21.0623 278.039 21.4123 276.666 21.4002C275.264 21.414 273.884 21.057 272.665 20.3655ZM279.104 18.1313C279.846 17.6941 280.454 17.063 280.864 16.3057C281.288 15.5153 281.51 14.6323 281.51 13.7354C281.51 12.8384 281.288 11.9554 280.864 11.1651C280.452 10.4047 279.84 9.7713 279.095 9.3334C278.349 8.89549 277.498 8.66969 276.633 8.68041C275.75 8.66932 274.88 8.89703 274.115 9.33947C273.368 9.77416 272.755 10.4057 272.342 11.1651C271.914 11.9542 271.69 12.8377 271.69 13.7354C271.69 14.6331 271.914 15.5166 272.342 16.3057C272.755 17.0651 273.368 17.6966 274.115 18.1313C274.88 18.5737 275.75 18.8014 276.633 18.7903C277.501 18.8007 278.356 18.5727 279.104 18.1313Z" fill="#31317B"/>
        <path d="M305.999 21.0893H303.205V9.7667L296.884 21.0893L290.564 9.8326V21.0893H287.77V6.38574H291.592L296.944 15.9948L302.216 6.38574H305.979L305.999 21.0893Z" fill="#31317B"/>
        <path d="M57.0678 32.0032C56.5814 31.7433 56.0375 31.6096 55.4861 31.6144C55.0682 31.6137 54.655 31.7013 54.2734 31.8714C53.8942 32.0367 53.5565 32.2844 53.2848 32.5964C53.0149 32.8994 52.8226 33.2636 52.7246 33.6575H53.845C53.9747 33.3422 54.2069 33.0798 54.5041 32.9127C54.8138 32.7316 55.1669 32.6382 55.5256 32.6425C55.9698 32.6358 56.4028 32.7824 56.7514 33.0577C57.0976 33.3267 57.3322 33.7139 57.4105 34.1452H54.8863V35.1469H57.4105C57.3337 35.5769 57.0986 35.9626 56.7514 36.2278C56.4361 36.4778 56.052 36.6256 55.6504 36.6515C55.2488 36.6774 54.8489 36.5802 54.5041 36.3728C54.2073 36.2082 53.975 35.948 53.845 35.6346H52.7246C52.8241 36.0279 53.0161 36.3917 53.2848 36.6957C53.555 37.0077 53.8932 37.2535 54.2734 37.4141C54.655 37.5842 55.0682 37.6718 55.4861 37.6711C56.038 37.6803 56.583 37.5463 57.0678 37.2823C57.5286 37.0321 57.9099 36.6576 58.1684 36.2014C58.428 35.7241 58.564 35.1894 58.564 34.646C58.564 34.1027 58.428 33.568 58.1684 33.0907C57.9084 32.634 57.5276 32.2577 57.0678 32.0032Z" fill="#31317B"/>
        <path d="M65.1536 33.4009C65.48 33.2286 65.7517 32.9683 65.9379 32.6496C66.1136 32.3335 66.2023 31.9764 66.1949 31.6149C66.2011 31.2191 66.0985 30.8293 65.8983 30.4879C65.6924 30.1445 65.3898 29.8694 65.0284 29.697C64.6041 29.4898 64.1361 29.388 63.6641 29.4004H60.0195V37.5661H63.6773C64.1668 37.5768 64.652 37.473 65.0943 37.263C65.4697 37.0832 65.7838 36.7966 65.9972 36.4391C66.2091 36.0828 66.3165 35.674 66.3069 35.2594C66.3164 34.8787 66.2157 34.5034 66.0169 34.1786C65.8077 33.8436 65.5085 33.5742 65.1536 33.4009ZM61.1992 30.4747H63.6246C64.567 30.4747 65.0349 30.8767 65.0349 31.6742C65.0469 31.8506 65.0214 32.0275 64.9601 32.1934C64.8987 32.3592 64.803 32.5102 64.6791 32.6364C64.3818 32.8756 64.0051 32.9933 63.6246 32.9659H61.1992V30.4747ZM64.7318 36.1623C64.4183 36.399 64.0295 36.5137 63.6377 36.4853H61.1992V33.9809H63.6377C64.0272 33.955 64.4137 34.0644 64.7318 34.2906C64.8612 34.4037 64.9625 34.5453 65.0277 34.7043C65.0929 34.8633 65.1202 35.0353 65.1074 35.2067C65.1165 35.3853 65.0857 35.5638 65.0174 35.7291C64.949 35.8943 64.8447 36.0423 64.712 36.1623H64.7318Z" fill="#31317B"/>
        <path d="M71.0273 37.5594H72.1346V32.7813H75.1069V31.7334H71.0273V37.5594Z" fill="#31317B"/>
        <path d="M80.6879 32.0032C80.2677 31.7455 79.7836 31.6108 79.2907 31.6144C78.8948 31.6103 78.5045 31.7079 78.1571 31.8978C77.8031 32.0855 77.4966 32.3515 77.2608 32.6755V31.733H76.2129V39.5428H77.3201V36.6825C77.5553 36.9875 77.8545 37.2372 78.1966 37.4141C78.5357 37.5856 78.9107 37.6738 79.2907 37.6711C79.7841 37.6783 80.2691 37.5433 80.6879 37.2823C81.1104 37.0225 81.4526 36.6506 81.6765 36.208C81.924 35.7251 82.0486 35.1886 82.0389 34.646C82.0505 34.1033 81.9259 33.5663 81.6765 33.0841C81.4527 32.6396 81.1106 32.2656 80.6879 32.0032ZM80.6879 35.6742C80.5411 35.9715 80.3125 36.2208 80.0288 36.3925C79.7524 36.5652 79.432 36.6544 79.1061 36.6496C78.7784 36.6528 78.4564 36.5637 78.1769 36.3925C77.8941 36.2196 77.6658 35.9707 77.5178 35.6742C77.3583 35.3554 77.2791 35.0024 77.2872 34.646C77.2799 34.2876 77.359 33.9327 77.5178 33.6113C77.6679 33.318 77.8959 33.0717 78.1769 32.8995C78.4564 32.7284 78.7784 32.6393 79.1061 32.6425C79.432 32.6376 79.7524 32.7269 80.0288 32.8995C80.3109 33.0704 80.5392 33.317 80.6879 33.6113C80.8489 33.9322 80.9303 34.2871 80.9251 34.646C80.923 35.0052 80.8324 35.3583 80.6615 35.6742H80.6879Z" fill="#31317B"/>
        <path d="M85.6986 36.0898L83.5896 31.7334H82.2715L85.1054 37.4408L84.1959 39.5366H85.3954L88.8093 31.7334H87.5901L85.6986 36.0898Z" fill="#31317B"/>
        <path d="M89.7969 37.5594H90.9041V32.7813H93.9357V37.5594H95.043V31.7334H89.7969V37.5594Z" fill="#31317B"/>
        <path d="M96.9082 31.7334V37.5594H98.0154V32.7813H101.04V37.5594H102.148V31.7334H96.9082Z" fill="#31317B"/>
        <path d="M58.5638 29.4004H52.7246V30.4746H58.5638V29.4004Z" fill="#31317B"/>
      </svg>
    </div>
  );
};

export default Logo;